import React from 'react';

import { Link } from 'gatsby'
import Layout from '../components/Layout'

const Success = () => {
    return(
        <Layout>
            <div className="main-container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '30vh'}}>
                <p>Votre message a bien été reçu</p>
                <Link style={{display: 'block'}} to="/">Retourner vers l'accueil</Link>
            </div>
        </Layout>
    )   
}

export default Success;